.root {
  height: 100vh;
  overflow-y: scroll;
  background-color: black;
  color: white;
  padding: 16px;
}

.root::-webkit-scrollbar {
  display: none;
}

.drawer {
  width: 300px;
}

.title {
  display: block;
  margin-bottom: 32px;
  color: white;
}
